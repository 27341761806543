<template>
  <ib-drawer
    :size="size"
    :visible="visible"
    @close="$emit('close')"
  >
    <ib-expand-panel
      :value="true"
      inline
      closable
      :expandable="false"
      @close="$emit('close')"
    >
      <template #title>
        <div class="title-wrapper my-3 my-md-0">
          <div class="info-drawer-title">
            <i class="icon ib-icon ib-icon-info-3" />
            <div class="d-flex">
              Info
            </div>
          </div>
        </div>
      </template>
      <div class="info-guide-drawer-wrapper">
        <div v-if="guideExample" class="info-guide-content-wrapper">
          <!-- Image -->
          <img
            alt="image"
            class="content-img img-rounded"
            :src="getImgUrl()"
          >
          <!-- /Image -->

          <!-- Title -->
          <h3 class="font-outfit-medium text-left mb-3 mt-0">
            {{ guideExample.title }}
          </h3>
          <!-- /Title -->

          <!-- Text -->
          <p class="info-guide-text m-0 text-left" v-html="guideExample.text" />
          <!-- /Text -->
        </div>

        <!-- Divider -->
        <ib-divider block class="my-3" />
        <!-- /Divider -->

        <div class="d-flex justify-content-end">
          <ib-button-basic
            color="gray"
            @click="$emit('close')"
          >
            <span class="font-outfit-regular">{{ $t('aiBuddy.close') }}</span>
          </ib-button-basic>
        </div>
      </div>
    </ib-expand-panel>
  </ib-drawer>
</template>

<script>
import IbDrawer from '@/components/_v2/Drawer/IbDrawer'
import defaultGuideExampleImages from '@/data/defaultGuideExampleImages.json'
import { mapGetters } from 'vuex'

export default {
  name: 'InfoGuideDrawer',

  components: {
    IbDrawer
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      defaultGuideExampleImages
    }
  },

  computed: {
    ...mapGetters('idea', ['getGuideExample']),

    size () {
      return this.$breakpoint.lgAndUp ? '768px' : '100%'
    },

    guideExample () {
      return this.getGuideExample(this.type)
    }
  },

  methods: {
    getImgUrl () {
      if (this.guideExample && this.guideExample.image) {
        return `${this.guideExample.image.path}${this.guideExample.image.filename}`
      }

      return require(`@/assets/img/illustrations/guide-examples/${this.defaultGuideExampleImages[this.type]}`)
    }
  }
}
</script>

<style scoped lang="scss">
.info-guide-drawer-wrapper {
  .info-guide-content-wrapper {
    text-align: center;

    @include media-breakpoint-up($md) {
      text-align: left;
    }

    h3 {
      font-size: 18px;
    }

    .content-img {
      width: 80%;
      margin: 0 0 20px;

      @include media-breakpoint-up($md) {
        float: right;
        width: 35%;
        margin: 10px 20px 20px;
      }
    }

    .img-rounded {
      border-radius: 0.75rem;
    }

    .info-guide-text {
      ::v-deep {
        line-height: 25px;

        .bullet {
          position: relative;
          visibility: visible;
          display: block;
          padding-left: 30px;
          line-height: 2;

          &:before {
            content: '•';
            position: absolute;
            left: 15px;
            top: -8px;
            font-size: 22px;
          }
        }
      }
    }
  }
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 36px;
  padding: 10px 0 10px 10px;

  @include media-breakpoint-up($md) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    padding: 20px 0 10px 10px;
  }

  .info-drawer-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: $color-primary;
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    .icon {
      //font-size: 22px !important;
      margin-top: 3px;
    }
  }
}
</style>
